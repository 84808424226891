.productSelector {
  .container {
    width: 210px;
    margin: 5px;
    .label {
      margin: 5px 0;
      color: white;
      font-size: 10pt;
    }
    .infoIcon{
      background: url('https://static.hungermapdata.org/hungermap/img/info_icon.png');
      background-size: 15px 15px;
      position: fixed;
      margin: -1px 2px;
      font-size: 2px;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }
}

@media screen and (max-height: 930px) {
  .productSelector {
    .container {
      width: 200px;
      margin: 3px;
      .label {
        font-size: 9pt;
      }
    }
  }
}

@media screen and (max-height: 720px) {
  .productSelector {
    .container {
      width: 180px;
      margin: 2px;
      .label {
        font-size: 8pt;
      }
    }
  }
}