.collapsable {
    .collapsableHeader {
        width: 100%;
        position: relative;
        cursor: default;
        display: block; 
        align-items: center; 
        padding-top: 0px; 
        justify-content: right;
    }

    svg {
        fill: white;
    }

    .collapseIcon {
        position: absolute;
        top: 0px;
        right: 15px;
        height: 20px;
        cursor: pointer;
        
        .iconUpDown {
            transform: rotateZ(90deg);
            -ms-transform: rotateZ(90deg);
            -webkit-transform: rotateZ(90deg);
        }
        .iconRightLeft {
            transform: rotateZ(5deg);
            -ms-transform: rotateZ(5deg);
            -webkit-transform: rotateZ(5deg);
        }
        .icon {
            width: 0.5em;
        }
        .iconCollapsed {
            transition: transform 300ms cubic-bezier(0, 1, 0, 1);
            -webkit-transition: transform 300ms cubic-bezier(0, 1, 0, 1);
            -moz-transition: transform 300ms cubic-bezier(0, 1, 0, 1);
            -o-transition: transform 300ms cubic-bezier(0, 1, 0, 1);
        }
        .iconExpanded {
            transition: transform 300ms cubic-bezier(0, 1, 0, 1);
            -webkit-transition: transform 300ms cubic-bezier(0, 1, 0, 1);
            -moz-transition: transform 300ms cubic-bezier(0, 1, 0, 1);
            -o-transition: transform 300ms cubic-bezier(0, 1, 0, 1);
            transform: rotateZ(-90deg);
            -ms-transform: rotateZ(-90deg);
            -webkit-transform: rotateZ(-90deg);
        }
    }
}