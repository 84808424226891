@keyframes pulseAnimation {
    0% {opacity: 1;}
    50% {opacity: 0;}
    100% {opacity: 1;}
}
@keyframes pulseAnimationReflection {
    0% {opacity: 0.2;}
    50% {opacity: 0;}
    100% {opacity: 0.2;}
}
.pulse {
    animation: pulseAnimation 1s infinite;
}
.pulseReflection {
    animation: pulseAnimationReflection 1s infinite;
}

.MapMenu {
    position: fixed;
    bottom: -28px;
    left: 50%;
    margin-left: -440px;
    z-index: 900;
    background: url('https://static.hungermapdata.org/hungermap/img/bottom_bar.png') no-repeat;
    background-size: 880px 60px;
    width: 880px;
    height: 60px;

    &.intro {
        background: rgba(0, 0, 0, 0);
    }

    .inner {
        color: #fff;
        text-align: center;
        display: flex;
        justify-content: space-around;
        margin: 0 auto;
        width: 720px;
    }
}

.tippy-tooltip.introTooltip {
    font-size: 9pt;
    border-radius: 8px;
    font-weight: bold;

    &.ipc {
        color: #fff;
        background-color: #D66102;
        .tippy-arrow {
            border-top-color: #D66102;
        }
    }
    &.fcs {
        color: #000;
        background-color: #00BFE3;
        .tippy-arrow {
            border-top-color: #00BFE3;
        }
    }
}

.MenuItem {
    width: 110px;
    display: flex;
    flex-direction: column;
    margin-top: -80px;

    &.intro {
        .title {
            opacity: 0;
            &.highlighted {
                opacity: 1;
            }
        }
        img, &.active img {
            opacity: 0;
            cursor: default;
        }
    }

    &.disabled .icon, &.disabled .reflection,  {
      filter: brightness(5) grayscale(1);
    }

    .icon {
        width: 65px;
        opacity: 0.6;
        position: relative;
        top: 13px;
    }
    .reflection {
        position: relative;
        width: 65px;
        -webkit-transform: scaleY(-1);
        left: 0;
        top: 0;
        transform: scaleY(-1);
        opacity: 0.2;
    }
    .title {
        font-size: 9pt;
    }

    &.fcs {
        .icon {
            top: -2px;
        }
        .reflection {
            top: -16px;
        }

        &.active {
            position: relative;
            .title {
                position: relative;
                top: -15px;
            }
            .icon {
                top: -20px;
            }
            .reflection {
                top: -45px;
            }
        }
    }

    &.conflict {
        .icon {
            top: 8px;
        }
        .reflection {
            position: relative;
            top: 6px;
        }

        &.active {
            position: relative;
            top: -10px;
            .icon {
                top: -3px;
            }
            .reflection {
                top: -10px;
            }
        }
    }

    &.ipc {
        .icon {
            width: 60px;
            position: relative;
            top: 18px;
        }
        &.active {
            .icon {
                position: relative;
                top: 5px;
                width: 78px;
            }
            .reflection {
                top: -10px;
                width: 78px;
            }
        }
        .reflection {
            position: relative;
            top: 6px;
            width: 60px;
        }
    }

    &.active {
        position: relative;
        top: -3px;
        .icon {
            width: 90px;
            opacity: 1;
            position: relative;
            top: -3px;
        }
        .reflection {
            width: 90px;
            position: relative;
            top: -32px;
        }
    }

    &.clickable {
        .icon {
            cursor: pointer;
        }
    }

}
@media screen and (max-height: 930px) {
    .MapMenu {
        bottom: -22px;
        margin-left: -350px;
        background-size: 700px 50px;
        width: 700px;
        height: 50px;

        .inner {
            width: 560px;
        }
    }

    .MenuItem {
        width: 110px;
        margin-top: -80px;

        .icon {
            width: 55px;
            top: 20px;
        }
        .reflection {
            width: 55px;
            top: 8px;
        }
        .title {
            font-size: 7.5pt;
        }

        &.ipc {
            .icon {
                width: 52px;
                position: relative;
                top: 25px;
            }
            &.active {
                .icon {
                    position: relative;
                    top: 16px;
                    width: 65px;
                }
                .reflection {
                    top: 5px;
                    width: 65px;
                }
            }
            .reflection {
                position: relative;
                width: 52px;
                top: 15px;
            }
        }

        &.conflict {
            .icon {
                top: 18px;
            }
            .reflection {
                top: 15px;
            }

            &.active {
                top: -10px;
                .icon {
                    width: 70px;
                    top: 14px;
                }
                .reflection {
                    top: 12px;
                }
            }
        }

        &.fcs {
            .icon {
                top: 8px;
            }
            .reflection {
                top: -2px;
            }

            &.active {
                position: relative;
                .title {
                    position: relative;
                    top: -5px;
                }
                .icon {
                    top: -4px;
                }
                .reflection {
                    top: -22px;
                }
            }
        }

        &.active {
            top: -3px;
            .icon {
                width: 75px;
                top: 10px;
            }
            .reflection {
                width: 75px;
                top: -10px;
            }
        }
    }
}

@media screen and (max-height: 720px) {
  .MapMenu {
    bottom: -18px;
    margin-left: -300px;
    background-size: 600px 40px;
    width: 600px;
    height: 40px;

    .inner {
      width: 500px;
    }
  }

  .MenuItem {
    width: 80px;
    margin-top: -80px;

    .icon {
      width: 45px;
      top: 30px;
    }
    .reflection {
      width: 45px;
      top: 22px;
    }
    .title {
      position: relative;
      top: 18px;
      font-size: 6.5pt;
    }

    &.ipc {
      .icon {
        width: 45px;
        position: relative;
        top: 30px;
      }
      &.active {
        .icon {
          position: relative;
          top: 28px;
          width: 52px;
        }
        .reflection {
          top: 22px;
          width: 52px;
        }
      }
      .reflection {
        position: relative;
        width: 45px;
        top: 25px;
      }
      &.disabled {
        .icon {
          width: 48px;
          top: 25px;
        }
        .reflection {
          width: 48px;
          top: 18px;
        }
      }
    }

    &.conflict {
      .icon {
        top: 28px;
      }
      .reflection {
        top: 25px;
      }

      &.active {
        top: -10px;
        .icon {
          width: 55px;
          top: 28px;
        }
        .reflection {
          width: 55px;
          top: 26px;
        }
      }
    }

    &.fcs {
      .title {
        top: 2px;
      }

      .icon {
        top: 15px;
        width: 50px;
      }
      .reflection {
        width: 50px;
        top: 8px;
      }

      &.active {
        position: relative;
        .title {
          position: relative;
          top: 8px;
        }
        .icon {
          width: 60px;
          top: 12px;
        }
        .reflection {
          width: 60px;
          top: -4px;
        }
      }
    }

    &.active {
      top: -3px;
      .icon {
        width: 65px;
        top: 18px;
      }
      .reflection {
        width: 65px;
        top: 0;
      }
    }
  }
}