.TopBar {
    position: fixed;
    top: -50px;
    left: 50%;
    margin-left: -440px;
    z-index: 900;
    background: url('https://static.hungermapdata.org/hungermap/img/top_bar.png') no-repeat;
    background-size: 880px 150px;
    width: 900px;
    height: 140px;
    color: #fff;

    .title {
        width: 280px;
        font-size: 15px;
        font-weight: bold;
    }

    .title-country {
        width: 350px;
        font-size: 15px; 
        font-weight: bold
    }

    .content {
        width: 650px;
        display: table;
        margin: 0 auto;
        padding-top: 55px;
        div {
            display: table-cell;
            vertical-align: middle;
            box-sizing: border-box;
        }
    }
    .center {
        width: 800px;
        
    }
    .left, .right {
        width: 240px;

    }
    .left {
        text-align: right;
        padding-right: 10px;
    }
    .right {
        div {
            display: inline-block;
            box-sizing: border-box;
        }
        .countryName {
            width: 210px;
            padding-left: 10px;
            border: 0;
            &.active {
                border-left: 2px solid white;
            }
        }
        .dataType {
            font-size: 8pt;
            white-space: nowrap;
        }
    }
    .live {
        position: relative;
        display: inline-block;
        top: -8px;
        margin-left: 5px;
        vertical-align: super;
        font-size: 7pt;
        width: 25px;
    }
    .logo {
        display: inline-block;
        height: 50px;
    }
    .link {
        cursor: pointer;
        display: inline-block;

    }
}

@media screen and (max-height: 1030px) {
    .TopBar {
        top: -40px;
        margin-left: -350px;
        background-size: 700px 130px;
        width: 700px;
        height: 130px;

        .title {
            width: 280px;
            font-size: 17px;
            font-weight: bold;
        }
    
        .title-country {
            width: 300px;
            font-size: 17px; 
            font-weight: bold
        }    

        .content {
            width: 530px;
            padding-top: 45px;
        }
        .center {
            width: 130px;
           
        }
        .left, .right {
            width: 200px;
        }
        .left {
            padding-right: 18px;
            padding-top: 3px;
        }
        .right {
            .countryName {
                width: 140px;
                padding-left: 10px;
                font-size: 8.5pt;
            }
            .dataType {
                font-size: 6.5pt;
            }
        }
        .live {
            top: -6px;
            margin-left: 0;
            font-size: 7pt;
            width: 25px;
        }
        .logo {
            height: 40px;
        }
    }

}


@media screen and (max-height: 930px) {
    .TopBar {
        top: -40px;
        margin-left: -350px;
        background-size: 700px 130px;
        width: 700px;
        height: 130px;

        .title {
            width: 280px;
            font-size: 15px;
            font-weight: bold;
        }
    
        .title-country {
            width: 300px;
            font-size: 15px; 
            font-weight: bold
        }    

        .content {
            width: 530px;
            padding-top: 45px;
        }
        .center {
            width: 130px;
           
        }
        .left, .right {
            width: 200px;
        }
        .left {
            padding-right: 18px;
            padding-top: 3px;
        }
        .right {
            .countryName {
                width: 140px;
                padding-left: 10px;
                font-size: 8.5pt;
            }
            .dataType {
                font-size: 6.5pt;
            }
        }
        .live {
            top: -6px;
            margin-left: 0;
            font-size: 7pt;
            width: 25px;
        }
        .logo {
            height: 40px;
        }
    }
}

@media screen and (max-height: 720px) {
    .TopBar {
        top: -30px;
        margin-left: -300px;
        background-size: 600px 115px;
        width: 600px;
        height: 115px;

        .content {
            width: 430px;
            padding-top: 35px;
        }
        .center {
            width: 130px;
            font-size: 14pt;
            text-align: center;
        }
        .left, .right {
            width: 150px;
        }
        .left {
            padding-right: 0px;
            padding-top: 5px;
        }
        .right {
            .countryName {
                width: 130px;
                padding-left: 10px;
                font-size: 8.5pt;
            }
            .dataType {
                font-size: 6.5pt;
            }
        }
        .live {
            top: -5px;
            margin-left: -5px;
            font-size: 6pt;
            width: 25px;
        }
        .logo {
            height: 30px;
            padding-left: 10px;
        }
    }
}



// @media screen and (max-height: 1000px) {
//     .TopBar {
//         top: -30px;
//         margin-left: -300px;
//         background-size: 600px 115px;
//         width: 600px;
//         height: 115px;

//         .content {
//             width: 800px;
//             padding-top: 35px;
//             font-size: '10px'
//         }

//         .center {
//             width: 800px !important;
//         }
//     }
// }