$legends-tall-screen-margin-side: 400px;
$legends-short-screen-margin-side: 310px;
$legends-small-screen-margin-side: 280px;
$legends-tall-screen-margin-bottom: 150px;
$legends-short-screen-margin-bottom: 120px;
$legends-small-screen-margin-bottom: 100px;

$right-legends-pane-top: 30px;
$right-legends-pane-right: 30px;
$right-legends-pane-bottom: 0px;
$right-legends-pane-width: 400px;
$right-alert-legends-width: 200px;

$fcsAlert-color: #cb1956;
$marketAccessAlert-color: #ec6f75;
$covidAlert-color: #acacac;
$conflictAlert-color: #742280;
$climateWetAlert-color: #4295D3;
$climateDryAlert-color: #B95926;
$hazardAlert-color: #fff046;



// $box-back-color: RGBA(46, 66, 71, 0.8);
// $dark-background-color: rgba(0, 0, 0, 0.5);
$box-back-color: black;
$dark-background-color: rgba(0, 0, 0, 0.5);

@function legendTileColor($color) {
  @return desaturate(adjust-hue(invert($color), 180), 20%);
}

.legend {
  z-index: 900;
  background-color: RGBA(46, 66, 71, 0.8);
  border: 0;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  transition-property: top, right, bottom, left;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  font-size: 7pt;
  line-height: 1.8;
  border-bottom-right-radius: 20px;
  -webkit-border-bottom-right-radius: 20px;

  &.reports {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    top: -30px;
    left: -250px;
    padding: 10px;
    width: 220px;

    a {
      color: #fff;
      text-decoration: none;
      font-weight: 600;
    }

    .iconBox {
      width: 100px;
      text-align: center;
      font-size: 8pt;
      line-height: 1.3;
    }

    img {
      width: 70px;
      height: 70px;
    }

    &.active {
      top: 100px;
      left: 40px;
    }

    .snapshotTooltip {
      max-width: inherit !important;
      background-color: RGBA(46, 66, 71, 0.8);
      white-space: nowrap;
      .tippy-arrow {
        border-bottom-color: RGBA(46, 66, 71, 0.8);
      }
      p {
        margin: 5px;
      }
      a:hover {
        text-decoration: underline;
      }
      hr {
        border: 1px solid rgba(255, 255, 255, 0.3);
      }
      ul ul {
        padding-left: 1em;
      }
    }
  }

  &.pdc {
    width: 500px;
    height: 100px;
    right: 80px;
    bottom: -150px;
    &.active {
      right: 100px;
      bottom: $legends-tall-screen-margin-bottom;
      &.countryPage {
        right: $legends-tall-screen-margin-side;
        &.withFcs {
          bottom: 270px;
        }
        &.withIpc {
          bottom: $legends-tall-screen-margin-bottom;
          right: 740px;
        }
      }

      &.withFcs {
        bottom: 220px;
      }
      &.withIpc {
        bottom: 240px;
      }
    }
    .text {
      box-sizing: border-box;
      width: 100%;
      padding: 10px 10px 0 10px;
    }
    .icons {
      box-sizing: border-box;
      width: 100%;
      padding: 10px 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-size: 10pt;
      .warning {
        color: #ff0000;
      }
      .watch {
        color: #fbed21;
      }
      .advisory {
        color: #54ac53;
      }
      .information {
        color: #2597ee;
      }
      .termination {
        color: #b2b4b6;
      }
    }
  }

  &.acled {
    width: 500px;
    right: -550px;
    bottom: -110px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    padding: 10px 0;
    div.item {
      min-width: 72px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 0 5px;
    }

    &.active {
      right: 100px;
      bottom: $legends-tall-screen-margin-bottom;
      &.countryPage {
        right: $legends-tall-screen-margin-side;
        &.withFcs {
          bottom: 270px;
        }
        &.withIpc {
          bottom: $legends-tall-screen-margin-bottom;
          right: 740px;
        }
      }

      &.withFcs {
        bottom: 220px;
      }
      &.withIpc {
        bottom: 240px;
      }
    }
    .marker {
      display: inline-block;
      margin: 8px;
      width: 15px;
      height: 15px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      &.battles {
        background-color: RGBA(134, 0, 47, 0.9);
      }
      &.violence {
        background-color: RGBA(162, 200, 236, 0.9);
      }
      &.explosions {
        background-color: RGBA(255, 187, 122, 0.9);
      }
      &.riots {
        background-color: RGBA(201, 82, 0, 0.9);
      }
      &.protests {
        background-color: RGBA(13, 101, 125, 0.9);
      }
      &.developments {
        background-color: RGBA(207, 207, 207, 0.9);
      }
    }
  }

  &.fcs {
    width: 505px;
    right: 200px;
    bottom: -110px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .header {
      display: block;
      padding: 10px 10px 0 10px;
    }
    &.active {
      right: 20px;
      bottom: 100px;
      &.countryPage {
        right: $legends-tall-screen-margin-side;
        bottom: $legends-tall-screen-margin-bottom;
      }
    }
    table {
      margin: 10px;
      border-spacing: 0;
      tr.descriptions td {
        padding-top: 5px;
      }
      td {
        padding: 0;
        border: 0;
        width: 75px;
        line-height: 1.2;

        &.marker {
          height: 5px;
          font-size: 2pt;

          &.cell1 {
            background-color: #29563a;
          }
          &.cell2 {
            background-color: #73b358;
          }
          &.cell3 {
            background-color: #cbcc58;
          }
          &.cell4 {
            background-color: #d5a137;
          }
          &.cell5 {
            background-color: #eb5a26;
          }
          &.cell6 {
            background-color: #d3130c;
          }
        }
      }
    }
  }

  &.ndvi {
    width: 180px;
    height: 260px;
    right: -200px;
    top: calc(50% - 150px);

    &.active {
      right: 100px;
      &.countryPage {
        right: $legends-tall-screen-margin-side;
      }
    }
    table {
      border-spacing: 0;
      td {
        text-align: right;
        font-size: 8pt;
        width: 65px;
        padding: 0;
      }
      tr.meta td {
        font-size: 10pt;
        text-align: left;
        padding: 5px 0;
      }
      .marker {
        display: block;
        width: 60px;
        height: 15px;
        font-size: 2pt;
        margin: 0;
        border-bottom-right-radius: 5px;
        -webkit-border-bottom-right-radius: 5px;
        &.cell1 {
          background-color: legendTileColor(#b67b2f);
        }
        &.cell2 {
          background-color: legendTileColor(#ef7230);
        }
        &.cell3 {
          background-color: legendTileColor(#f3b549);
        }
        &.cell4 {
          background-color: legendTileColor(#e9dfa1);
        }
        &.cell5 {
          background-color: legendTileColor(#fff);
          margin: 7px 0;
        }
        &.cell6 {
          background-color: legendTileColor(#c7fe4f);
        }
        &.cell7 {
          background-color: legendTileColor(#32f340);
        }
        &.cell8 {
          background-color: legendTileColor(#22972f);
        }
        &.cell9 {
          background-color: legendTileColor(#195a20);
        }
      }
    }
  }

  &.rainfall {
    width: 180px;
    height: 260px;
    right: -200px;
    top: calc(50% - 150px);

    &.active {
      right: 100px;
      &.countryPage {
        right: $legends-tall-screen-margin-side;
      }
    }
    table {
      border-spacing: 0;
      td {
        text-align: right;
        font-size: 8pt;
        width: 65px;
        padding: 0;
      }
      tr.meta td {
        font-size: 10pt;
        text-align: left;
        padding: 5px 0;
      }
      .marker {
        display: block;
        width: 60px;
        height: 15px;
        font-size: 2pt;
        margin: 0;
        border-bottom-right-radius: 5px;
        -webkit-border-bottom-right-radius: 5px;
        &.cell1 {
          background-color: legendTileColor(#b67b2f);
        }
        &.cell2 {
          background-color: legendTileColor(#ef9f3c);
        }
        &.cell3 {
          background-color: legendTileColor(#f9ce8a);
        }
        &.cell4 {
          background-color: legendTileColor(#ffecb9);
        }
        &.cell5 {
          background-color: legendTileColor(#fff);
          margin: 7px 0;
        }
        &.cell6 {
          background-color: legendTileColor(#b4f8f8);
        }
        &.cell7 {
          background-color: legendTileColor(#72e2ea);
        }
        &.cell8 {
          background-color: legendTileColor(#16bddd);
        }
        &.cell9 {
          background-color: legendTileColor(#1582d4);
        }
      }
    }
  }

  &.ipcGlobal {
    width: 525px;
    right: -550px;
    bottom: -110px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .header {
      display: block;
      padding: 10px 10px 0 10px;
    }
    &.active {
      right: 100px;
      bottom: $legends-tall-screen-margin-bottom;
    }
    table {
      margin: 10px 30px 10px 10px;
      border-spacing: 0;
      tr.descriptions td {
        padding-top: 5px;
      }
      td {
        padding: 0;
        border: 0;
        width: 75px;
        line-height: 1.2;

        &.marker {
          height: 5px;
          font-size: 2pt;

          &.cell1 {
            background-color: #f6d1c1;
          }
          &.cell2 {
            background-color: #fc9b7d;
          }
          &.cell3 {
            background-color: #fb7453;
          }
          &.cell4 {
            background-color: #f24634;
          }
          &.cell5 {
            background-color: #d11f26;
          }
          &.cell6 {
            background-color: #ae151b;
          }
          &.cell7 {
            background-color: #710013;
          }
          &.cell8 {
            background-color: #012128;
          }
        }
        &.small {
          font-size: 8pt;
        }
      }
    }
  }

  &.ipcCountry {
    width: 310px;
    right: -330px;
    bottom: -270px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
    .title {
      display: block;
      font-size: 11pt;
      padding-top: 10px;
    }
    .subtitle {
      font-size: 9pt;
    }
    table {
      td.left {
        width: 50px;
        vertical-align: top;
        padding-top: 7px;
      }
    }
    .marker {
      display: block;
      font-size: 2pt;
      height: 12px;
      width: 28px;
      &.phase1 {
        background-color: #d1fad1;
      }
      &.phase2 {
        background-color: #fae834;
      }
      &.phase3 {
        background-color: #e88519;
      }
      &.phase4 {
        background-color: #cd1919;
      }
      &.phase5 {
        background-color: #731919;
      }
      &.phase6 {
        background-color: #353f42;
        border: 1px solid #222;
      }
    }

    &.active {
      right: $legends-tall-screen-margin-side;
      bottom: $legends-tall-screen-margin-bottom;
    }
  }
}

.topRightLegends {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transition-property: top, right, bottom, left;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  height: 0px;
  top: calc(#{$right-legends-pane-top} - 500px);
  right: calc(#{$right-legends-pane-right} - 500px);

  &.active {
    top: 100px;
    right: 80px;
  }

  .alerts-wrapper {
    z-index: 900;
    display: flex;
    height: 100px;
    flex-direction: column;
    align-items: flex-end;
    align-content: flex-end;
    align-items: center;
  }

  .alert-toggle {
    //display: inline-block;
    //flex-direction: row !important;
    //align-items: center;
  }

  .svg-rect {
    stroke-linejoin: round; /* Ensures the corners of the stroke are rounded */
}

  .alerts {
    font-size: 10pt;
    line-height: 1.8;
    background-color: $box-back-color;
    border: 0;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: #fff;
    border-bottom-right-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    margin-top: 10px;
    width: 215px;
    display: none;
    margin-left: 10px;

    .collapseIcon {
      margin-top: 5px;
    }

    &.fcsAlerts {
      &.active {
        display: block;
      }
    }

    &.covidAlerts {
      &.active {
        display: block;
      }
    }
    &.alertsLegend {
      &.active {
        display: block;
        width: 250px;
        padding: 5px;
      }
    }

    .header {
      display: block;
      padding: 5px 10px;
    }
    .alertTitle {
      padding: 0 10px;
    }

    .content {
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
    }

    .marker {
      width: 50px;
      position: relative;
      display: inline-flex;
      left: 10px;
      top: 10px;
    }

    .desc {
      padding: 4px 4px 8px 5px;
      line-height: 1.1;
    }
    .alert-title {
      padding-left: 16px;
    }
    .alertIcon {
      border-radius: 10px;
      display: inline-block;
      vertical-align: middle;
      &.fcsAlertIcon {
        border: 1px solid $fcsAlert-color;
      }
      &.covidAlertIcon {
        border: 1px solid $covidAlert-color;
      }
      &.marketAccessAlertIcon {
        border: 1px solid $marketAccessAlert-color;
      }
      &.conflictAlertIcon {
        border: 1px solid $conflictAlert-color;
      }
      &.dryAlertIcon {
        border: 1px solid $climateDryAlert-color;
      }
      &.wetAlertIcon {
        border: 1px solid $climateWetAlert-color;
      }
      &.hazardAlertIcon {
        border: 1px solid $hazardAlert-color;
      }
    }
    .alertIconInner {
      border-radius: 15px;
      margin: 2px;
      height: 10px;
      width: 10px;
      &.fcsAlertIcon {
        background-color: $fcsAlert-color;
        border: 1px solid $fcsAlert-color;
      }
      &.covidAlertIcon {
        background-color: $covidAlert-color;
        border: 1px solid $covidAlert-color;
      }
      &.marketAccessAlertIcon {
        background-color: $marketAccessAlert-color;
        border: 1px solid $marketAccessAlert-color;
      }
      &.conflictAlertIcon {
        background-color: $conflictAlert-color;
        border: 1px solid $conflictAlert-color;
      }
      &.dryAlertIcon {
        background-color: $climateDryAlert-color;
        border: 1px solid $climateDryAlert-color;
      }
      &.wetAlertIcon {
        background-color: $climateWetAlert-color;
        border: 1px solid $climateWetAlert-color;
      }
      &.hazardAlertIcon {
        background-color: $hazardAlert-color;
        border: 1px solid $hazardAlert-color;
      }
    }
    .alertMarkerCore {
      border-radius: 25px;
      height: 12px;
      width: 12px;
      position: relative;
    }
  }
}

.collapsedLegend {
  z-index: 900;
  background: url(https://static.hungermapdata.org/hungermap/img/legend_icon_ring.png);
  background-size: 32px 32px;
  background-position: center;
  background-color: $box-back-color;
  background-repeat: no-repeat;
  border-radius: 50%;
  box-shadow: 0px 0px 15px #0000007a;
  width: 32px;
  height: 32px;
  right: 30px;
  top: calc(80% - 50px);
  cursor: pointer;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: background-color .5s, top 0.3s, right 0.3s ease-in;
  transition: background-color .5s, top 0.3s, right 0.3s ease-in;

  &.countryPage {
    right: 380px;
    bottom: 115px;
  }

  span {
    color: #fff;
    font-weight: 700;
    font-size: 14pt;
    display: block;
    background-color: $box-back-color;
  }
}

.legendsWrapper {
  z-index: 1000;

  svg {
    fill: white;
  }

  .iconCollapse {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 8px;
    height: 20px;
    margin-top: 5px;
    width: 0.5em;
  }

  .pdc,
  .acled,
  .fcs,
  .ipcCountry,
  .ipcGlobal {
    .infoHeader {
      margin: 4px 25px 4px 10px;
    }
  }
  .ndvi,
  .rainfall {
    .infoHeader {
      margin: 4px 20px 4px 10px;
    }
  }
}

.tippy-tooltip.legendIconToolTip {
  font-size: 9pt;
  color: #fff;
  border-radius: 6px;
  background-color: $dark-background-color;
  .tippy-arrow {
    border-left-color: $dark-background-color;
  }
}

@media screen and (max-height: 1300px) {
  .legend {
    &.alerts {
      &.fcsAlerts {
        height: 95px;
        right: -200px;
        &.active {
          top: 300px;
          right: 250px;
        }
      }

      &.covidAlerts {
        height: 170px;
        right: -500px;
        &.active {
          top: 300px;
          right: 70px;
        }
      }

      font-size: 9pt;
      width: 170px;
      height: 95px;

      .content {
        height: 70px;
      }
      .marker {
        width: 50px;
        height: 70px;
        left: 5px;
        top: 10px;
        right: 5px;
      }
      .desc {
        width: 115px;
        height: 60px;
        font-size: 8px;
      }
    }
    &.reports {
      top: -30px;
      left: -250px;
      padding: 10px;
      width: 185px;

      .iconBox {
        font-size: 7pt;
        width: 85px;
      }
      img {
        width: 65px;
        height: 65px;
      }

      &.active {
        top: 80px;
        left: 30px;
      }
      .snapshotTooltip {
        font-size: 7pt;
        p {
          margin: 4px;
        }
      }
    }
  }
  .legend.active {
    &.countryPage {
      &.pdc,
      &.acled,
      &.fcs,
      &.ndvi,
      &.rainfall {
        right: $legends-short-screen-margin-side;
        bottom: $legends-short-screen-margin-bottom;
      }
      &.acled,
      &.pdc {
        &.withFcs {
          bottom: 240px;
        }
        &.withIpc {
          right: 650px;
          bottom: $legends-short-screen-margin-bottom;
        }
      }
    }
    &.pdc,
    &.acled,
    &.fcs,
    &.ndvi,
    &.rainfall {
      bottom: $legends-short-screen-margin-bottom;
    }
    &.ipcCountry {
      right: $legends-short-screen-margin-side;
      bottom: $legends-short-screen-margin-bottom;
    }
    &.fcs {
      bottom: 200px;
    }
    &.acled,
    &.pdc {
      &.withFcs {
        bottom: 240px;
      }
      &.withIpc {
        bottom: 240px;
      }
    }
  }

  .topRightLegends {
    &.active {
      top: 15px;
      right: 24px;
    }
    .alerts-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      align-content: flex-end;
    }

    .alertsLegend {
      right: -500px;
      &.active {
        top: 300px;
        right: 70px;
      }
    }

    .alerts {
      font-size: 9pt;
      width: 225px;
      margin-left: 10px;

      .desc {
        display: inline-block;
        padding: 5;
        font-size: 8px;
      }
    }
  }

  .collapsedLegend {
    &.countryPage {
      right: 310px;
      bottom: 115px;
    }
  }
  .legendsWrapper {
    .pdc,
    .acled,
    .fcs,
    .ipcCountry,
    .ipcGlobal {
      .infoHeader {
        margin: 6px 25px 4px 4px;
      }
    }
    .ndvi,
    .rainfall {
      .infoHeader {
        margin: 6px 20px 4px 4px;
      }
    }
  }
}


@media screen and (max-height: 930px) {
  .legend {
    &.alerts {
      &.fcsAlerts {
        height: 95px;
        right: -200px;
        &.active {
          top: 300px;
          right: 250px;
        }
      }

      &.covidAlerts {
        height: 170px;
        right: -500px;
        &.active {
          top: 300px;
          right: 70px;
        }
      }

      font-size: 9pt;
      width: 170px;
      height: 95px;

      .content {
        height: 70px;
      }
      .marker {
        width: 50px;
        height: 70px;
        left: 20px;
        top: 20px;
      }
      .desc {
        width: 115px;
        height: 60px;
        font-size: 8px;
      }
    }
    &.reports {
      top: -30px;
      left: -250px;
      padding: 10px;
      width: 185px;

      .iconBox {
        font-size: 7pt;
        width: 85px;
      }
      img {
        width: 65px;
        height: 65px;
      }

      &.active {
        top: 80px;
        left: 30px;
      }
      .snapshotTooltip {
        font-size: 7pt;
        p {
          margin: 4px;
        }
      }
    }
  }
  .legend.active {
    &.countryPage {
      &.pdc,
      &.acled,
      &.fcs,
      &.ndvi,
      &.rainfall {
        right: $legends-short-screen-margin-side;
        bottom: $legends-short-screen-margin-bottom;
      }
      &.acled,
      &.pdc {
        &.withFcs {
          bottom: 240px;
        }
        &.withIpc {
          right: 650px;
          bottom: $legends-short-screen-margin-bottom;
        }
      }
    }
    &.pdc,
    &.acled,
    &.fcs,
    &.ndvi,
    &.rainfall {
      bottom: $legends-short-screen-margin-bottom;
    }
    &.ipcCountry {
      right: $legends-short-screen-margin-side;
      bottom: $legends-short-screen-margin-bottom;
    }
    &.fcs {
      bottom: 140px;
    }
    &.acled,
    &.pdc {
      &.withFcs {
        bottom: 240px;
      }
      &.withIpc {
        bottom: 240px;
      }
    }
  }

  .topRightLegends {
    &.active {
      top: 15px;
      right: 20px;
    }
    .alerts-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      align-content: flex-end;
    }

    .alertsLegend {
      right: -500px;
      &.active {
        top: 300px;
        right: 70px;
      }
    }

    .alerts {
      font-size: 9pt;
      width: 225px;
      margin-left: 10px;

      .desc {
        display: inline-block;
        padding: 5px;
        font-size: 8px;
      }
    }
  }

  .collapsedLegend {
    &.countryPage {
      right: 310px;
      bottom: 115px;
    }
  }
  .legendsWrapper {
    .pdc,
    .acled,
    .fcs,
    .ipcCountry,
    .ipcGlobal {
      .infoHeader {
        margin: 6px 25px 4px 4px;
      }
    }
    .ndvi,
    .rainfall {
      .infoHeader {
        margin: 6px 20px 4px 4px;
      }
    }
  }
}

@media screen and (max-height: 720px) {
  .legend {
    &.alerts {
      &.fcsAlerts {
        height: 95px;
        right: -200px;
        &.active {
          top: 300px;
          right: 250px;
        }
      }

      &.covidAlerts {
        height: 170px;
        right: -500px;
        &.active {
          top: 300px;
          right: 70px;
        }
      }

      font-size: 9pt;
      width: 170px;
      height: 95px;

      .content {
        height: 70px;
      }
      .marker {
        width: 50px;
        height: 70px;
        left: 20px;
        top: 20px;
      }
      .desc {
        width: 115px;
        height: 60px;
        font-size: 8px;
      }
    }
    &.reports {
      top: -30px;
      left: -250px;
      padding: 10px;
      width: 185px;

      .iconBox {
        font-size: 7pt;
        width: 85px;
      }
      img {
        width: 65px;
        height: 65px;
      }

      &.active {
        top: 80px;
        left: 30px;
      }
      .snapshotTooltip {
        font-size: 7pt;
        p {
          margin: 4px;
        }
      }
    }
  }
  .legend.active {
    &.countryPage {
      &.pdc,
      &.acled,
      &.fcs,
      &.ndvi,
      &.rainfall {
        right: $legends-short-screen-margin-side;
        bottom: $legends-short-screen-margin-bottom;
      }
      &.acled,
      &.pdc {
        &.withFcs {
          bottom: 240px;
        }
        &.withIpc {
          right: 650px;
          bottom: $legends-short-screen-margin-bottom;
        }
      }
    }
    &.pdc,
    &.acled,
    &.fcs,
    &.ndvi,
    &.rainfall {
      bottom: $legends-short-screen-margin-bottom;
    }
    &.ipcCountry {
      right: $legends-short-screen-margin-side;
      bottom: $legends-short-screen-margin-bottom;
    }
    &.fcs {
      bottom: 140px;
    }
    &.acled,
    &.pdc {
      &.withFcs {
        bottom: 240px;
      }
      &.withIpc {
        bottom: 240px;
      }
    }
  }

  .topRightLegends {
    &.active {
      top: 28px;
      right: 15px;
    }
    .alerts-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      align-content: flex-end;
    }

    .alertsLegend {
      right: -500px;
      &.active {
        top: 300px;
        right: 70px;
      }
    }

    .alerts {
      font-size: 9pt;
      width: 225px;
      margin-left: 10px;

      .desc {
        display: inline-block;
        padding: 3;
        font-size: 6pt;
      }
    }
  }

  .collapsedLegend {
    &.countryPage {
      right: 310px;
      bottom: 115px;
    }
  }
  .legendsWrapper {
    .pdc,
    .acled,
    .fcs,
    .ipcCountry,
    .ipcGlobal {
      .infoHeader {
        margin: 6px 25px 4px 4px;
      }
    }
    .ndvi,
    .rainfall {
      .infoHeader {
        margin: 6px 20px 4px 4px;
      }
    }
  }
}


@media screen and (max-height: 610px) {
  .legend {
    &.alerts {
      &.fcsAlerts {
        height: 95px;
        right: -200px;
        &.active {
          top: 300px;
          right: 250px;
        }
      }

      &.covidAlerts {
        height: 170px;
        right: -500px;
        &.active {
          top: 300px;
          right: 70px;
        }
      }

      font-size: 9pt;
      width: 150px;
      height: 40px;

      .content {
        height: 40px;
      }
      .marker {
        width: 50px;
        height: 70px;
        left: 20px;
        top: 20px;
      }
      .desc {
        width: 55px;
        height: 40px;
        font-size: 8px;
      }
    }
    &.reports {
      top: -30px;
      left: -250px;
      padding: 10px;
      width: 185px;

      .iconBox {
        font-size: 7pt;
        width: 85px;
      }
      img {
        width: 65px;
        height: 65px;
      }

      &.active {
        top: 80px;
        left: 30px;
      }
      .snapshotTooltip {
        font-size: 7pt;
        p {
          margin: 4px;
        }
      }
    }
  }
  .legend.active {
    &.countryPage {
      &.pdc,
      &.acled,
      &.fcs,
      &.ndvi,
      &.rainfall {
        right: $legends-short-screen-margin-side;
        bottom: $legends-short-screen-margin-bottom;
      }
      &.acled,
      &.pdc {
        &.withFcs {
          bottom: 240px;
        }
        &.withIpc {
          right: 650px;
          bottom: $legends-short-screen-margin-bottom;
        }
      }
    }
    &.pdc,
    &.acled,
    &.fcs,
    &.ndvi,
    &.rainfall {
      bottom: $legends-short-screen-margin-bottom;
    }
    &.ipcCountry {
      right: $legends-short-screen-margin-side;
      bottom: $legends-short-screen-margin-bottom;
    }
    &.fcs {
      bottom: 106px;
    }
    &.acled,
    &.pdc {
      &.withFcs {
        bottom: 100px;
      }
      &.withIpc {
        bottom: 100px;
      }
    }
  }

  .topRightLegends {
    &.active {
      top: 15px;
      right: 15px;
    }
    .alerts-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      align-content: flex-end;
    }

    .alertsLegend {
      right: -500px;
      &.active {
        top: 300px;
        right: 70px;
      }
    }

    .alerts {
      font-size: 9pt;
      width: 225px;
      margin-left: 10px;

      .desc {
        display: inline-block;
        padding: 3px;
        font-size: 8px;
      }
    }
  }

  .collapsedLegend {
    &.countryPage {
      right: 10px;
      bottom: 115px;
    }
  }
  .legendsWrapper {
    .pdc,
    .acled,
    .fcs,
    .ipcCountry,
    .ipcGlobal {
      .infoHeader {
        margin: 6px 25px 4px 4px;
      }
    }
    .ndvi,
    .rainfall {
      .infoHeader {
        margin: 6px 20px 4px 4px;
      }
    }
  }
}