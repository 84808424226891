.countrySelector {
  width: 250px;
  height: 50px;
  //position: absolute;
  //top: -50px;
  //left: -280px;
  //background-color: RGBA(46, 66, 71, 0.8);
  //transition-property: top, right, bottom, left;
  //transition-duration: 0.5s;
  //transition-timing-function: ease-out;
  //border: 0;
  //backdrop-filter: blur(5px);
  //-webkit-backdrop-filter: blur(5px);
  //border-bottom-right-radius: 20px;
  //-webkit-border-bottom-right-radius: 20px;
  //display: flex;
  //justify-content: center;
  //align-items: center;

  //&.active {
  //  top: 40px;
  //  left: 40px;
  //}

  .container {
    width: 220px;
    margin: 5px;
    .label {
      margin: 5px 0;
      color: white;
      font-size: 10pt;
    }
  }
}

@media screen and (max-height: 930px) {
  .countrySelector {
    height: 40px;
    width: 200px;

    .container {
      width: 180px;
      margin: 3px;
      .label {
        font-size: 9pt;
      }
    }
  }
}

@media screen and (max-height: 720px) {
  .countrySelector {
    height: 35px;
    width: 180px;

    .container {
      width: 160px;
      margin: 2px;
      .label {
        font-size: 8pt;
      }
    }
  }
}