.countryPane {
  border: 0;
  color: #fff;
  width: 350px;
  position: fixed;
  box-sizing: border-box;
  z-index: 900;
  transition: left 0.5s ease-out, right 0.5s ease-out;
  display: flex;
  flex-direction: column;
  font-size: 10pt;

  .infoHeader {
    top: -4px;
    right: 25px;
  }

  .countryInsightDownload {
    position: fixed;
    top: 10px;
    width: 300px;
    font-size: 1.2em;
  }

  .link {
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    :hover {
      text-decoration: underline;
    }
    img{
      max-width: 20px;
      margin: 0 5px;
    }
  }

  .collapseIcon { 
    margin-top: 2px;
  }

  &.rightPane {
    top: 40px;
  }

  &.leftPane {
    left: -370px;
    top: 20px;
    .leftDetailsContainer {
      background-color: RGBA(36, 36, 36, 0.8);
      padding: 10px 5px 5px 10px;
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
    }
    &.active {
      left: 20px;
      padding: 0px;
    }
  }
  &.rightPane {
    right: -370px;
    .rightDetailsContainer {
      background-color: RGBA(36, 36, 36, 0.8);
      padding: 10px 5px 5px 10px;
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
    }
    &.active {
      right: 20px;
    }
    .collapsableContent {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  &.insightReport {
    top: 20px;
    width: 120px;
    right: -370px;
    text-align: center;
    a, a:visited {
      color: #fff;
      text-decoration: none;
    }
    &.active {
      right: 430px;
    }
    .iconBox {
      font-size: 8pt;
      width: 85px;
    }
    img {
      width: 65px;
      height: 65px;
    }
  }

  &.covidPane {
    background-color: RGBA(36, 36, 36, 0.8);
    bottom: 120px;
    left: -370px;
    padding: 18px 0 10px 18px;
    overflow-y: hidden;
    &.active {
      left: 390px;
    }
    .chartSummary {
      text-align: center;
      margin-top: 15px
    }
    .deltaChart {
      margin-top: 10px;
      display: inline-block;
      text-align: center;
    }
  }

  .section {
    box-sizing: border-box;
    margin: 0;
    position: relative;

    .header {
      font-size: 13pt;
      margin-bottom: 8px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.7);
      padding-bottom: 1px;
      width: calc(100% - 15px);
    }

    .indicator {
      margin-bottom: 10px;
      display: flex;
      img {
        object-fit: contain;
        max-width: 55px;
        max-height: 55px;
        width: auto;
        height: auto;
        margin-right: 10px;
      }
      .content {
        display: inline-block;
        span.name {
          display: block;
          font-size: 11pt;
        }
        div.value, div.info {
          display: inline-block;
        }
        div.value {
          min-width: 85px;
          font-size: 20pt;
          margin: 0;
          padding: 0;
          vertical-align: bottom;
          span.unit {
            display: inline-block;
            margin-left: 5px;
            font-size: 16pt;
            color: #02C5EF;
          }
          span.nodata {
            color: #02C5EF;
            font-size: 15pt;
          }
        }
        .info {
          font-size: 9pt;
        }
      }

      .trendbox {
        position: relative;
        top: -15px;
        background-color: #000;
        width: 120px;
        margin-left: 20px;
        margin-top: 0;
        margin-right: 25px;
        font-size: 8pt;

        .smallNoData {
          font-size: 8pt;
        }

        img {
          width: 15px;
          margin-right: 3px;
        }
        .negative {
          color: #1AFA29;
          font-weight: bold;
          font-size: 12pt;
        }
        img.positive {
          transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          -o-transform: rotate(180deg);
        }
        .positive {
          color: #D22379;
          font-weight: bold;
          font-size: 12pt;
        }
      }
    }
  }
}

.leftPane {
  .chartbox {
    height: 180px;
  }
  .foodSecurity.section .indicator .content div.value {
    vertical-align: top;
  }
}
.rightPane {
  .chartbox {
    height: 120px;
  }
}
.chartbox {
  .graphNoData {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 15pt;
    color: #02C5EF;
  }

  .info {
    display: block;
    // padding-top: 5px;
  }
}

.covid.section {
  .summary {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 10pt;
    margin-bottom: 12px;
    .datum {
      display: inline-block;
      margin-right: 15pt;
      .value {
        font-size: 12pt;
        font-weight: bold;
      }
    }
    .confirmed.datum .value {
      color: #E08004;
    }
    .deaths.datum .value {
      color: #D3130C;
    }
  }
}

@media screen and (max-height: 930px) {
  .countryPane {
    width: 280px;
    font-size: 7pt;
    padding: 12px 0 12px 12px;

    .infoHeader {
      top: -5px;
      right:25px;
    }

    &.rightPane {
      top: 30px;
    }

    &.leftPane {
      left: -300px;
      top: 20px;

      &.active {
        left: 15px;
      }
    }
    &.rightPane {
      right: -300px;
      .collapsableContent {
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }
    &.insightReport {
      top: 15px;
      width: 100px;
      &.active {
        right: 360px;
      }
      .iconBox {
        font-size: 7pt;
        width: 80px;
      }
      img {
        width: 60px;
        height: 60px;
      }
    }

    &.covidPane {
      bottom: 115px;
      left: -310px;
      padding: 12px 0 2px 12px;
      &.active {
        left: 310px;
      }
    }

    .section {
      .header {
        font-size: 10pt;
        margin-bottom: 8px;
        width: calc(100% - 10px);
      }

      .indicator {
        margin-bottom: 6px;
        img {
          max-width: 40px;
          max-height: 40px;
          margin-right: 8px;
        }
        .content {
          span.name {
            font-size: 9pt;
          }
          div.value {
            min-width: 67px;
            font-size: 16pt;
            span.unit {
              margin-left: 3px;
              font-size: 13pt;
            }
            span.nodata {
              font-size: 13pt;
            }
          }
          .info {
            font-size: 8pt;
          }
        }

        .trendbox {
          right: 8px;
          padding: 2px 8px;
          width: 90px;
          margin-right: 15px;
          font-size: 6.5pt;

          .smallNoData {
            font-size: 6.5pt;
          }

          img {
            width: 11px;
            margin-right: 2px;
          }
          .negative {
            font-size: 9pt;
          }
          .positive {
            color: #D22379;
            font-weight: bold;
            font-size: 9pt;
          }
        }
      }
    }
  }

  .leftPane {
    .chartbox {
      height: 140px;
      .graphNoData {
        height: 135px;
      }
    }
  }
  .rightPane {
    .chartbox {
      height: 90px;
      .graphNoData {
        height: 85px;
      }
    }
  }
  .chartbox {
    .graphNoData {
      font-size: 13pt;
    }
  }

  .covid.section {
    .summary {
      font-size: 9pt;
      margin-bottom: 10px;
      .datum {
        margin-right: 12pt;
        .value {
          font-size: 10pt;
        }
      }
    }
  }
}

@media screen and (max-height: 720px) {
  .countryPane {
    width: 260px;
    font-size: 7.5pt;
    padding: 10px 0 10px 10px;

    .infoHeader {
      top: -4px;
      right: 25px;
    }

     &.rightPane {
      top: 25px;
    }

    &.leftPane {
      left: -280px;
      top: 15px;
      &.active {
        left: 10px;
      }
    }
    &.rightPane {
      right: -280px;
      &.active {
        right: 10px;
      }
      .collapsableContent {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
    &.insightReport {
      top: 15px;
      width: 90px;
      &.active {
        right: 320px;
      }
      .iconBox {
        font-size: 6pt;
        width: 75px;
      }
      img {
        width: 55px;
        height: 55px;
      }
    }

    &.covidPane {
      bottom: 100px;
      max-height: 280px;
      left: -275px;
      padding: 10px 0 1.5px 10px;
      &.active {
        left: 275px;
      }
    }

    .section {
      .header {
        font-size: 9pt;
        padding-top: 5px;
        // margin-bottom: 6px;
        width: calc(100% - 8px);
      }

      .indicator {
        margin-bottom: 5px;
        img {
          max-width: 35px;
          max-height: 35px;
          margin-right: 7px;
        }
        .content {
          span.name {
            font-size: 8pt;
          }
          div.value {
            min-width: 60px;
            font-size: 13pt;
            span.unit {
              margin-left: 3px;
              font-size: 11pt;
            }
            span.nodata {
              font-size: 11pt;
            }
          }
          .info {
            font-size: 7.5pt;
          }
        }

        .trendboxContainer {
          right: 7px;
        }
        .trendbox {
          top: 5px;
          padding: 1px 7px;
          width: 85px;
          margin-right: 12px;
          font-size: 5.5pt;

          .smallNoData {
            font-size: 5.5pt;
          }

          img {
            width: 9px;
            margin-right: 1px;
          }
          .negative {
            font-size: 8pt;
          }
          .positive {
            font-size: 8pt;
          }
        }
      }
    }
  }

  .leftPane {
    .chartbox {
      height: 130px;
      .graphNoData {
        height: 100px;
      }
    }
  }
  .rightPane {
    .chartbox {
      height: 70px;
      .graphNoData {
        height: 80px;
      }
    }
  }
  .chartbox {
    .graphNoData {
      font-size: 13pt;
    }
  }

  .covid.section {
    .summary {
      font-size: 7.5pt;
      margin-bottom: 6px;
      .datum {
        margin-right: 10pt;
        .value {
          font-size: 8.5pt;
        }
      }
    }
  }
}
