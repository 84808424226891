@keyframes spinnerAnimation {
  100% {
    transform:rotate(360deg);
  }
}
.blueSpinner {
  animation: spinnerAnimation 10s linear infinite;
}
.greenSpinner {
  animation: spinnerAnimation 12s linear infinite;
}

html, body {
  height: 100%;
  margin: 0;
  background-color: #0C1E39;
  overflow: hidden;
}

.numberOverlay {
  position: fixed;
  left: -300px;
  z-index: 900;
  width: 250px;
  height: 250px;
  color: #fff;
  font-size: 11pt;
  text-align: center;
  transition: left 0.5s ease-out;
  padding-bottom: 10px;

  &.active {
    left: 50px;
  }

  .value {
    display: block;
    font-size: 18pt;
    font-weight: bold;
  }

  .background, .content {
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .background {
    position: absolute;
    top: 0;
    left: 0;
  }
  .content {
    position: absolute;
    top: -5px;
    left: 0;
  }
}
.peopleOverlay {
  top: calc(60% - 250px);
}

.countriesOverlay {
  top: 60%;
}

.ipcTooltip, .fcsTooltip {
  .content {
    width: 320px;
    &.wide{
      width: 400px;
    }
    .geoname {
      font-size: 11pt;
      display: inline-block;
      margin-bottom: 6px;
      font-weight: bold;
    }
    .value {
      font-size: 11pt;
      font-weight: bold;
      color: rgb(224, 128, 4);
    }
    .conf {
      color: #aaa;
    }
    p {
      margin-bottom: 7px;
      margin-top: 3px;
    }
  }
  white-space: normal;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  color: #fff;
  border: 0;
  padding: 20px;
  &.leaflet-tooltip-right::before, &.leaflet-tooltip-left::before {
    display: none;
  }
}

.ipcTooltip {
  width: 320px;
}

.fcsTooltip {
  .chartTitle {
    font-size: 8pt;
    text-align: center;
  }
  .chartBox {
    .graphNoData{
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      font-size: 15pt;
      color: #02C5EF;
    }
  }
}

.backButton {
  cursor: pointer;
  display: flex;
  z-index: 900;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  width: 200px;
  height: 45px;
  left: calc(50% - 100px);
  top: -50px;
  background: url('https://static.hungermapdata.org/hungermap/img/global_button.png');
  background-size: 200px 45px;
  font-size: 12pt;
  color: #fff;
  text-align: center;
  transition: top 0.5s ease-out;

  &.goto {
    z-index: 890;
  }

  span {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.active {
    top: 100px;
  }
}

.countryTooltip {
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  color: #fff;
  border: 0;
  &:before {
    border: 0;
  }
}

.rainfallLayer, .ndviLayer {
  filter: invert(100%) hue-rotate(180deg) saturate(80%) blur(0.5px);
}

.subscribe {
  position: fixed;
  right: 20px;
  top: 0;
  z-index: 900;

  .button {
    width: 100px;
    margin-top: 10px;
  }
}
.tippy-tooltip.subscribeTooltip {
  font-size: 10pt;
  color: #fff;
  background-color: #111;
  &.bottom {
    .tippy-arrow {
      border-bottom-color: #111;
    }
  }
  &.left {
    .tippy-arrow {
      border-left-color: #111;
    }
  }
}

@media screen and (max-height: 930px) {
  .peopleOverlay {
    top: calc(60% - 200px);
  }
  .numberOverlay {
    left: -220px;
    width: 200px;
    height: 200px;
    font-size: 9pt;
    padding-bottom: 8px;

    &.active {
      left: 30px;
    }

    .value {
      font-size: 14pt;
    }

    .background, .content {
      width: 200px;
      height: 200px;
    }
  }

  .subscribe {
    right: 15px;

    .button {
      width: 80px;
      margin-top: 8px;
    }
  }
  .tippy-tooltip.subscribeTooltip {
    font-size: 8pt;
  }
}

@media screen and (max-height: 720px) {
  .backButton {
    width: 160px;
    height: 35px;
    left: calc(50% - 80px);
    top: -50px;
    background-size: 160px 35px;
    font-size: 10pt;

    span {
      height: 26px;
    }

    &.active {
      top: 80px;
    }
  }

  .peopleOverlay {
    top: calc(60% - 180px);
  }
  .numberOverlay {
    left: -180px;
    width: 180px;
    height: 180px;
    font-size: 8pt;
    padding-bottom: 7px;

    &.active {
      left: 26px;
    }

    .value {
      font-size: 12pt;
    }

    .background, .content {
      width: 180px;
      height: 180px;
    }
  }

  .subscribe {
    right: 10px;

    .button {
      width: 70px;
      margin-top: 6px;
    }
  }
  .tippy-tooltip.subscribeTooltip {
    font-size: 7pt;
  }
}

.dropdownsContainer {
  z-index: 1200;
  display: flex;
  position: absolute;
  transition-property: top, right, bottom, left;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  top: -80px;
  left: -280px;

  &.active {
    top: 65px;
    left: 40px;
  }
}

@media screen and (max-height: 930px) {
  .dropdownsContainer {
    &.active {
      top: 50px;
      left: 30px;
    }
  }
}

@media screen and (max-height: 720px) {
  .dropdownsContainer {
    &.active {
      top: 50px;
      left: 20px;
    }
  }
}