$border-radius: 12px;
$small-border-radius: 10px;

.zoomControl {
  z-index: 900;
  position: fixed;
  right: 20px;
  top: 84%;
  transition: top 0.5s ease-out, right 0.5s ease-out;
  display: flex;
  flex-direction: column;
  padding: 0;

  div.zoomIn, div.zoomOut {
    background-color: rgba(lighten(#2e4247, 15%), 0.6);
    border: 0;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 32px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1px;
    color: #fff;
    font-size: 15pt;
    font-weight: bold;
    cursor: pointer;
    span {
      display: block;
    }
  }
  div.zoomIn {
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
    -webkit-border-top-right-radius: $border-radius;
    -webkit-border-top-left-radius: $border-radius;
    -moz-border-top-right-radius: $border-radius;
    -webkit-border-top-left-radius: $border-radius;
  }
  div.zoomOut {
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    -webkit-border-bottom-right-radius: $border-radius;
    -webkit-border-bottom-left-radius: $border-radius;
    -moz-border-bottom-right-radius: $border-radius;
    -webkit-border-bottom-left-radius: $border-radius;
  }

  &.country {
    right: 380px;
    top: 12%;
  }
}

@media screen and (max-height: 930px) {
  .zoomControl.country {
    right: 310px;
    top: 50%;
  }
}

@media screen and (max-height: 720px) {
  .zoomControl {
    right: 30px;
    top: 80%;
    margin-top: 20px;

    div.zoomIn {
      border-top-right-radius: $small-border-radius;
      border-top-left-radius: $small-border-radius;
      -webkit-border-top-right-radius: $small-border-radius;
      -webkit-border-top-left-radius: $small-border-radius;
      -moz-border-top-right-radius: $small-border-radius;
      -webkit-border-top-left-radius: $small-border-radius;
    }
    div.zoomOut {
      border-bottom-right-radius: $small-border-radius;
      border-bottom-left-radius: $small-border-radius;
      -webkit-border-bottom-right-radius: $small-border-radius;
      -webkit-border-bottom-left-radius: $small-border-radius;
      -moz-border-bottom-right-radius: $small-border-radius;
      -webkit-border-bottom-left-radius: $small-border-radius;
    }

    div.zoomIn, div.zoomOut {
      width: 28px;
      height: 30px;
      font-size: 13pt;
    }
  }

  .zoomControl.country {
    right: 10px;
    bottom: 130px;
    top: 450px;
  }
}