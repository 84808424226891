$covidAlert-color: #acacac;
//$fcsAlert-color: #26BDE2;
//$fcsAlert-color: #82c7e2;
$fcsAlert-color: #cb1956;
$marketAccessAlert-color: #ec6f75;
$conflictAlert-color: #742280;
$climateWetAlert-color: #4295D3;
$climateDryAlert-color: #B95926;
$hazardAlert-color: #fff046;

@keyframes borderAnimation {
  0%   { width: 10px; height: 10px; top: 0px; left: 0px }
  40%  { width: 30px; height: 30px; top: -10px; left: -10px }
  100% { width: 30px; height: 30px; top: -10px; left: -10px }

  0%   { opacity: 0 }
  25%  { opacity: 1 }
  42%  { opacity: 0 }
  100% { opacity: 0 }
}
.border1 {
  animation: borderAnimation 5s infinite;
  animation-delay: 0s;
}
.border2 {
  animation: borderAnimation 5s infinite;
  animation-delay: 0.4s;
}
.border3 {
  animation: borderAnimation 5s infinite;
  animation-delay: 0.8s;
}

.alertMarker {
  fill-opacity: 1;
  stroke-opacity: 0 ;
}

.borderContainer {
  background-color: rgba(0, 0, 0, 0);
}

.alertMarkerBorder {
  border-radius: 20px;
  border: 1px solid;
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  left: 0;
}

.fcsAlert {
  fill: $fcsAlert-color;
  border-color: $fcsAlert-color;
}

.fcsAlertCore {
  background-color: $fcsAlert-color;
}

.covidAlert {
  fill: $covidAlert-color;
  border-color: $covidAlert-color;
}

.covidAlertCore {
  background-color: $covidAlert-color;
}

.marketAccessAlert {
  fill: $marketAccessAlert-color;
  border-color: $marketAccessAlert-color;
}

.marketAccessAlertCore {
  background-color: $marketAccessAlert-color;
}

.conflictAlert {
  fill: $conflictAlert-color;
  border-color: $conflictAlert-color;
}

.conflictAlertCore {
  background-color: $conflictAlert-color;
}

.climateDryAlert {
  fill: $climateDryAlert-color;
  border-color: $climateDryAlert-color;
}

.climateDryAlertCore {
  background-color: $climateDryAlert-color;
}

.climateWetAlert {
  fill: $climateWetAlert-color;
  border-color: $climateWetAlert-color;
}

.climateWetAlertCore {
  background-color: $climateWetAlert-color;
}

.hazardAlert {
  fill: $hazardAlert-color;
  border-color: $hazardAlert-color;
}

.hazardAlertCore {
  background-color: $hazardAlert-color;
}