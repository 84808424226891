.disclaimerPopup {
  display: none;
  background-color: RGBA(0, 0, 0, 0.8);
  border: 0;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: #fff;
  z-index: 1250;
  width: 95%;
  height: 92%;
  position: fixed;
  left: 2.5%;
  top: 3%;
  font-size: 13pt;
  line-height: 1.7;

  &.active {
    display: block;
  }

  .topright {
    float: right;
    padding: 10px;
  }
  .link {
    cursor: pointer;
  }
  .changelog-link {
    font-size: 0.8em;
    cursor: pointer;
    color: white;
    text-align: right;
    text-decoration: underline;
    font-style: italic;
  }
  .content {
    width: calc(100% - 40px);
    height: calc(100% - 90px);
    box-sizing: border-box;
    margin: 20px;
    padding: 0 30px 0 30px;
    overflow-y: auto;
  }
  .title {
    font-size: 20pt;
    display: block;
    margin-bottom: 1em;
    .live {
      font-size: 12pt;
      top: -8px;
    }
  }
  .live {
    position: relative;
    display: inline-block;
    top: -4px;
    margin-left: 5px;
    vertical-align: super;
    font-size: 9pt;
    width: 25px;
  }

  table.indicators {
    width: 90%;
    margin: 20px auto;
    border-collapse: collapse;
    text-align: center;
    th, td {
      border: 1px solid #555;
      padding: 5px 10px 5px 20px;
    }
    th {
      font-weight: normal;
    }
  }

  a {
    color: #1E8FFD;
    text-decoration: none;
    font-weight: 600;
  }
  p {
    margin-bottom: 1.6em;
  }
  h1 {
    font-size: 16pt;
    font-weight: normal;
    margin-bottom: 0;
  }
  h2 {
    font-size: 16pt;
    border-bottom: 1pt solid #555;
  }
}
table.glossary {
  border-collapse: collapse;
  td {
    vertical-align: top;
  }
  tr.header {
    font-weight: bold;
    border-bottom: 1pt solid #999;
    td {
      padding-top: 20px;
    }
  }
  .contentBottom {
    border-bottom: 1pt solid #555;
  }
  .firstColumn {
  }
  .secondColumn {
    text-align: center;
    width: 200px;
  }
  .thirdColumn {
    width: 350px;
  }
}
.glossaryControls {
  float: right;
  .orderCriteria {
    display: inline-block;
    margin: 0 0 0 10px;
    font-weight: 600;
    cursor: pointer;
    &.active {
      color: #29AAE1;
      cursor: default;
    }
  }
  input {
    font-size: 12pt;
    background-color: #000;
    color: #fff;
    border: 1px solid #555;
    margin: 10px;
    width: 200px;
    padding: 5px;
    &:focus {
      border: 1px solid #29AAE1;
      outline: none;
    }
  }
}
@media screen and (max-height: 930px) {
  .disclaimerPopup {
    font-size: 11pt;
    .title {
      font-size: 17pt;
      .live {
        font-size: 10pt;
      }
    }
  }
}

@media screen and (max-height: 720px) {
  .disclaimerPopup {
    font-size: 10pt;
    .title {
      font-size: 15pt;
      .live {
        font-size: 9pt;
      }
    }
    .live {
      font-size: 7pt;
    }
  }
}

