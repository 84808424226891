.mapAttribution {
  z-index: 900;
  position: fixed;
  right: 0;
  bottom: 0;
  padding-bottom: 3px;
  padding-right: 20px;
  font-size: 10pt;
  color: #fff;
  text-align: right;
  line-height: 1;

  a {
    color: #fff;
  }
}
@media screen and (max-height: 930px) {
  .mapAttribution {
    font-size: 7.5pt;
  }
}

@media screen and (max-height: 720px) {
  .mapAttribution {
    font-size: 7pt;
  }
}