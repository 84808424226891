.acledPopup {
  .leaflet-popup-content-wrapper {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    color: #fff;
  }
  .leaflet-popup-tip-container .leaflet-popup-tip {
    background-color: rgba(0, 0, 0, 0.75);
  }
}

.acledClusterContainer {
  bacgkound-color: rgba(0, 0, 0, 0);
}

.acledCluster {
  border-radius: 30px;
  font-size: 1pt;
  border: 1px solid rgba(255, 255, 255, 0.7);
  padding: 0;
  margin: 0;
}