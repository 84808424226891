.infoHeader {
  background: url('https://static.hungermapdata.org/hungermap/img/info_icon.png');
  background-size: 20px 20px;
  margin: 4px 10px;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2px;
  width: 20px;
  height: 20px;
  cursor: pointer;

  @media screen and (max-height: 930px) {
    background-size: 17px 17px;
    width: 17px;
    height: 17px;
    margin: 4px 4px;
  }

  @media screen and (max-height: 720px) {
    background-size: 15px 15px;
    width: 15px;
    height: 15px;
    margin: 3.5px 3.5px;
  }
}