$info-overlays-tall-screen-margin-side: 400px;
$info-overlays-short-screen-margin-side: 310px;
$info-overlays-small-screen-margin-side: 280px;
$section-overlays-tall-screen-margin-side: 390px;
$section-overlays-short-screen-margin-side: 305px;
$section-overlays-small-screen-margin-side: 275px;


.infoOverlay {
  padding: 5px;
  margin: 0;
  background-color: RGBA(0, 0, 0, 0.8);
  border: 0;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: #fff;
  z-index: 1200;
  position: fixed;
  font-size: 10pt;
  width: 300px;
  height: 300px;

  a {
    color: #2e8ee2;
    font-weight: 600;
    text-decoration: none;
  }

  p {
    margin-top: 0;
    margin-bottom: 15px;
  }

  h1 {
    font-size: 13pt;
    margin-bottom: 3px;
  }

  .content {
    padding: 5px;
    overflow-y: auto;
    height: calc(100% - 40px);
  }

  .header {
    padding: 0 0 10px 0;
    margin: 0;
    display: flex;
    justify-content: flex-end;

    .closeButton {
      background: url('https://static.hungermapdata.org/hungermap/img/close_icon.png') -13px -14px;
      font-size: 2pt;
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }

  &.fcsLegend {
    right: 100px;
    bottom: 220px;
    &.countryPage {
      right: $info-overlays-tall-screen-margin-side;
      bottom: 270px;
    }
  }
  &.pdcLegend {
    right: 100px;
    bottom: 260px;
    &.countryPage {
      right: $info-overlays-tall-screen-margin-side;
      bottom: 260px;
      &.withFcs {
        bottom: 380px;
      }
      &.withIpc {
        right: 740px;
        bottom: 260px;
      }
    }
    &.withFcs {
      bottom: 330px;
    }
    &.withIpc {
      bottom: 350px;
    }
  }
  &.acledLegend {
    right: 100px;
    bottom: 245px;
    height: 240px;
    &.countryPage {
      right: $info-overlays-tall-screen-margin-side;
      bottom: 245px;
      &.withFcs {
        bottom: 370px;
      }
      &.withIpc {
        right: 740px;
        bottom: 245px;
      }
    }
    &.withFcs {
      bottom: 315px;
    }
    &.withIpc {
      bottom: 335px;
    }
  }
  &.ndviLegend, &.rainfallLegend {
    right: 300px;
    top: calc(50% - 150px);
    width: 250px;
    &.countryPage {
      right: 600px;
    }
  }
  &.ipcLegend {
    right: 100px;
    bottom: 245px;
    &.countryPage {
      right: $info-overlays-tall-screen-margin-side;
      bottom: 440px;
    }
  }
  &.foodSecuritySection {
    left: $section-overlays-tall-screen-margin-side;
    top: 40px;
  }
  &.nutritionSection {
    left: $section-overlays-tall-screen-margin-side;
    top: 20%;
  }
  &.foodSecurityTrendsSection {
    left: $section-overlays-tall-screen-margin-side;
    top: 30%;
  }
  &.macroEconomicSection {
    right: $section-overlays-tall-screen-margin-side;
    top: 40px;
  }
  &.productsAccessSection {
    height: 270px;
    width: 300px;
    left: 520px;
    top: 100px;
  }
}
@media screen and (max-height: 930px) {
  .infoOverlay.countryPage {
    &.pdcLegend, &.acledLegend, &.fcsLegend, &.ipcLegend {
      right: $info-overlays-short-screen-margin-side;
    }
    &.rainfallLegend, &.ndviLegend {
      right: 500px;
    }
    &.pdcLegend {
      bottom: 230px;
      &.withFcs {
        bottom: 355px;
      }
      &.withIpc {
        bottom: 230px;
        right: 650px;
      }
    }
    &.acledLegend {
      bottom: 215px;
      &.withFcs {
        bottom: 335px;
      }
      &.withIpc {
        bottom: 210px;
        right: 650px;
      }
    }
    &.fcsLegend {
      bottom: 245px;
    }
    &.ipcLegend {
      bottom: 400px;
      height: 250px;
    }
  }
  .infoOverlay {
    &.fcsLegend {
      bottom: 240px;
    }
    &.acledLegend {
      bottom: 220px;
      &.withFcs {
        bottom: 335px;
      }
      &.withIpc {
        bottom: 335px;
      }
    }
    &.pdcLegend {
      bottom: 230px;
      &.withFcs {
        bottom: 355px;
      }
      &.withIpc {
        bottom: 355px;
      }
    }
    &.foodSecuritySection, &.nutritionSection, &.foodSecurityTrendsSection {
      left: $section-overlays-short-screen-margin-side;
    }
    &.macroEconomicSection {
      right: $section-overlays-short-screen-margin-side;
    }
    &.foodSecuritySection, &.macroEconomicSection {
      top: 30px;
    }
    &.nutritionSection {
      top: 20%;
    }
    &.productsAccessSection {
      left: 440px;
      top: 80px;
    }
  }
}

@media screen and (max-height: 720px) {
  .infoOverlay {
    padding: 4px;
    font-size: 8pt;
    width: 260px;
    height: 260px;

    p {
      margin-bottom: 10px;
    }

    h1 {
      font-size: 11pt;
      margin-bottom: 2px;
    }

    .content {
      padding: 4px;
      height: calc(100% - 40px);
    }

    .header {
      padding: 0 0 8px 0;

      .closeButton {
        background: url('https://static.hungermapdata.org/hungermap/img/close_icon.png') -10px -10px;
        background-size: 35px 35px;
        font-size: 2pt;
        width: 18px;
        height: 18px;
      }
    }
  }

  .infoOverlay.countryPage {
    &.pdcLegend, &.acledLegend, &.fcsLegend, &.ipcLegend {
      right: $info-overlays-small-screen-margin-side;
    }
    &.rainfallLegend, &.ndviLegend {
      right: 450px;
      top: calc(50% - 130px);
    }
    &.pdcLegend {
      bottom: 190px;
      &.withFcs {
        bottom: 280px;
      }
      &.withIpc {
        bottom: 195px;
        right: 570px;
      }
    }
    &.acledLegend {
      bottom: 175px;
      &.withFcs {
        bottom: 265px;
      }
      &.withIpc {
        bottom: 175px;
        right: 570px;
      }
    }
    &.fcsLegend {
      bottom: 195px;
    }
    &.ipcLegend {
      bottom: 310px;
      height: 200px;
    }
  }
  .infoOverlay {
    &.pdcLegend {
      right: 90px;
      bottom: 190px;
      height: 250px;
      &.withFcs {
        bottom: 290px;
      }
      &.withIpc {
        bottom: 265px;
      }
    }

    &.acledLegend {
      right: 90px;
      bottom: 175px;
      height: 180px;

      &.withFcs {
        bottom: 270px;
      }
      &.withIpc {
        bottom: 245px;
      }
    }
    &.fcsLegend {
      bottom: 195px;
      right: 90px;
    }
    &.ndviLegend, &.rainfallLegend {
      right: 260px;
      top: calc(50% - 130px);
      width: 250px;
    }
    &.ipcLegend {
      right: 90px;
      bottom: 170px;
      height: 250px;
    }

    &.foodSecuritySection, &.nutritionSection, &.foodSecurityTrendsSection {
      left: $section-overlays-small-screen-margin-side;
    }
    &.macroEconomicSection {
      right: $section-overlays-small-screen-margin-side;
    }
    &.foodSecuritySection, &.macroEconomicSection {
      top: 30px;
    }
    &.nutritionSection {
      top: 15%;
    }
    &.productsAccessSection {
      left: 400px;
      top: 70px;
    }
  }
}