.leaflet-tooltip.adm1Label {
  font-size: 0.9em;
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  padding: 0;
  border: none;
  box-shadow: none;
}
