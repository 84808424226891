.pdcIcon {
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  transition: height 0.15s ease-out, width 0.15s ease-out, margin-top 0.15s ease-out, margin-left 0.15s ease-out;
}

.pdcIcon.active {
  width: 50px;
  height: 50px;
  margin-top: -25px;
  margin-left: -25px;
}
.pdcPopup {
  .leaflet-popup-content-wrapper {
    border-radius: 0px;
    background-color: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    color: #fff;
    width: 400px;
    height: 110px;
  }
  .leaflet-popup-tip-container {
    display: none;
  }

  .leaflet-popup-content {
    width: 360px !important;
    padding: 0px;
  }

  .content {
    display: flex;
    flex-direction: row;
    padding-top: 0px;
    img {
      width: 50px;
      height: 50px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px;
      border: 3px solid;
    }
  }
  .left, .right {
    display: flex;
    align-items: center;

    height: 85px;
    margin: 0px;
  }
  .left {
    width: 80px;
    margin-right: 10px;
    justify-content: center;
  }
  .right {
    width: 100%;
    justify-content: left;
  }

  .eventType {
    font-size: 13pt;
  }
}