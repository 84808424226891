.disclaimerLinks {
  z-index: 900;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  color: #fff;
  width: 400px;
  justify-content: space-around;
  padding-bottom: 4px;

  .link {
    display: block;
    cursor: pointer;
  }

  &.intro .link {
    cursor: default;
    opacity: 0;
    &.active {
      opacity: 1;
    }
  }
}

.tippy-tooltip.introTooltip {
  font-size: 9pt;
  border-radius: 8px;

  font-weight: bold;
  color: #fff;
  background-color: #444;
  .tippy-arrow {
    border-top-color: #444;
  }
  &.methodology {
    color: #000;
    background-color: #00BFE3;
    .tippy-arrow {
      border-top-color: #00BFE3;
    }
  }
}

@media screen and (max-height: 930px) {
  .disclaimerLinks {
    font-size: 9pt;
    width: 350px;
  }
}

@media screen and (max-height: 720px) {
  .disclaimerLinks {
    font-size: 8pt;
    width: 300px;
  }

  .tippy-tooltip.introTooltip {
    font-size: 6pt;
    border-radius: 6px;
  }
}
